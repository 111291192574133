import axios from 'axios';
import Vue from 'vue'
import App from './App.vue'
import routes from './router'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
window.axios = require('axios');
import VueRouter from 'vue-router';
import Toasted from 'vue-toasted';
import moment from 'moment';
import BootstrapVue from 'bootstrap-vue';
import DrawerLayout from 'vue-drawer-layout';
import VueTheMask from 'vue-the-mask';
import Constants from "./plugins/Constants";
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueTheMask);
Vue.use(Constants);
import vSelect from "vue-select";
Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAP_KEY,
        libraries: "places" //necessary for places input
    }
});
Vue.component("v-select", vSelect);
Vue.use(DrawerLayout)

// you can also pass options, check options reference below
Vue.use(Toasted)
Vue.use(BootstrapVue);
Vue.use(VueRouter);
axios.defaults.baseURL = process.env.VUE_APP_API_URL; //base url for all apis
axios.defaults.headers.common["Authorization"] = localStorage.getItem("consumer_token");

const router = new VueRouter({ mode: 'history', routes: routes });
Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY hh:mm')
    }
});

// axios interceptor code for error handling
axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    const originalRequest = error.config;
    if (error.response.data.code == 401 && error.response.data.data != null && !originalRequest._retry) {
        originalRequest._retry = true;
        axios.defaults.headers.common["Authorization"] = error.response.data.data;
        originalRequest.headers["Authorization"] = error.response.data.data;
        localStorage.setItem("consumer_token", error.response.data.data);
        return axios(originalRequest);
    } else if (error.response.data.code == 401) { // Written for JWT algorith change. If a consumer logged in with HS256 algo and did not logged out. After deploying the RS256 algo of JWT the consumer will face a Invalid JWT token issue. Hence logging out the consumer.
        localStorage.removeItem("consumer_token");
        localStorage.removeItem("nopurchasepower");
        localStorage.removeItem("purchasepower");
        localStorage.removeItem("consumer_login_response");
        localStorage.removeItem("enrollment");
        delete axios.defaults.headers.common["Authorization"];
        router.push("/login");
    }
    return Promise.reject(error);
});
Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');