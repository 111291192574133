const refreshAccountBalance = () => {
    return new Promise((res, rej) => {
        axios.get('refreshaccountbalance')
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const getUserdetails = (request) => {
    return new Promise((res, rej) => {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("consumer_token");
        axios.get("consumer/userProfile")
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const sendVerificationCode = (request) => {
    return new Promise((res, rej) => {
        axios.post('sendverificationcode', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const verifyVerificationCode = (request) => {
    return new Promise((res, rej) => {
        axios.post('verifyverificationcode', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const updateNewEmail = (request) => {
    return new Promise((res, rej) => {
        axios.post('updateemail', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const sendOtpyNewPhone = (request) => {
    return new Promise((res, rej) => {
        axios.post('sendotptonewnumber', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const verifyNewPhone = (request) => {
    return new Promise((res, rej) => {
        axios.post('verifyotpfornewphone', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const saveDetails = (request) => {
    return new Promise((res, rej) => {
        axios.post('update/userProfile', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const changeBank = (request) => {
    return new Promise((res, rej) => {
        axios.get('changeBankAccount', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const directLinkGenerate = (request) => {
    return new Promise((res, rej) => {
        axios.get('directlinkgenerate', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const directLinkBank = (request) => {
    return new Promise((res, rej) => {
        axios.get('directlinkbank', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const updateLinkGenerate = (request) => {
    return new Promise((res, rej) => {
        axios.get('updatelinkgenerate', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const updateBank = (request) => {
    return new Promise((res, rej) => {
        axios.get('updatebank', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const storeManualBankDetails = (request) => {
    return new Promise((res, rej) => {
        axios.post('integrator/savemanualbankdetails', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const consumerdeactivate = (request) => {
    return new Promise((res, rej) => {
        axios.post('deactivate', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getBankAccountList = (request) => {
    return new Promise((res, rej) => {
        axios.post("integrator/getconsumerbanklist", request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const saveBankDetailsFromList = (request) => {
    return new Promise((res, rej) => {
        axios.post('integrator/savebankfromlist', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const updateAccountNumber = (request) => {
    return new Promise((res, rej) => {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("consumer_token");
        axios.post('updatebankaccountno', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};


const updateDateOfBirth = (request) => {
    return new Promise((res, rej) => {
        axios.post('updatedateofbirth', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const changePIN = (request) => {
    return new Promise((res, rej) => {
        axios.post('changepin', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const generateConnectFix = (request) => {
    return new Promise((res, rej) => {
        axios.post('integrator/generateconnectfixurl', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const checkBankAccountState = () => {
    return new Promise((res, rej) => {
        axios.get('checkbankaccountstate')
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const uploadDocument = (request) => {
    var config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return new Promise((res, rej) => {
        axios.post('/uploadconsumerdocument', request, config)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const checkUserRequriedDocumentStatus = (request) => {
    return new Promise((res, rej) => {
        axios.post('checkuserrequrieddocumentstatus')
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
export default {
    refreshAccountBalance,
    getUserdetails,
    sendVerificationCode,
    verifyVerificationCode,
    updateNewEmail,
    verifyNewPhone,
    sendOtpyNewPhone,
    saveDetails,
    changeBank,
    storeManualBankDetails,
    consumerdeactivate,
    getBankAccountList,
    saveBankDetailsFromList,
    directLinkGenerate,
    directLinkBank,
    updateLinkGenerate,
    updateBank,
    updateAccountNumber,
    updateDateOfBirth,
    changePIN,
    generateConnectFix,
    checkBankAccountState,
    uploadDocument,
    checkUserRequriedDocumentStatus
};