<template>
  <div class="container">
    <div class="row">
      <!--Code for ToggleDrawer-->
      <!--Code for CanPay Logo-->


      <div class="col-10 header-logo-div">
        <img class="header-logo" src="../../../assets/images/canpay-logo.png" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Header",
  components: {

  },

  data: () => ({
    open: true,
    showmenuicon: true
  }),
  mounted() {
    let self = this;
    self.$root.$on("Menu Drawer Close", function(data) {
      self.showmenuicon = true;
    });

    self.$root.$on("Menu Drawer Open", function(data) {
      setTimeout(function() {
        self.showmenuicon = false;
      }, 30);
    });
  },

  methods: {
    showDrawer() {
      this.showmenuicon = false;
      this.$root.$emit("Menu Drawer", [""]);
    }
  }
};
</script>
