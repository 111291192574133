const loginUser = (request) => {
    return new Promise((res, rej) => {
        axios.post('consumer/login', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const logout = (request) => {
    return new Promise((res, rej) => {
        axios.get('logout', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const getConsumerStatus = (request) => {
    return new Promise((res, rej) => {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("consumer_token");
        axios.post('consumer/getconsumerstatus', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const consumerAccountVerifyCode = (request) => {
    return new Promise((res, rej) => {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("consumer_token");
        axios.post('consumer/consumeraccountverifycode', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const consumerAccountVerifyCodeResend = (request) => {
    return new Promise((res, rej) => {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("consumer_token");
        axios.post('consumer/consumersendverificationcode', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const linkConsumerWithIntegrator = (request) => {
    return new Promise((res, rej) => {
        axios.post('integrator/linkconsumerwithintegrator', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const checkConsumerReturn = (request) => {
    return new Promise((res, rej) => {
        axios.post('integrator/checkconsumerreturn', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const checkConsumerAuthAllowed = (request) => {
    return new Promise((res, rej) => {
        axios.post('integrator/checkconsumerauthallowed', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const checkStateAge = (request) => {
    return new Promise((res, rej) => {
        axios.post('consumer/checkstateage', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
export default {
    loginUser,
    logout,
    getConsumerStatus,
    consumerAccountVerifyCodeResend,
    consumerAccountVerifyCode,
    linkConsumerWithIntegrator,
    checkConsumerReturn,
    checkConsumerAuthAllowed,
    checkStateAge

};