export default {
    install(Vue, options) {
      Vue.prototype.$constants = function(...args) {
        const constants = [
          [
            "REQURIED_FIELD",
            "Please complete all required fields."
          ],
        ]
        return args.length
          ? Object.fromEntries(constants.filter(([key]) => args.includes(key)))
          : Object.fromEntries(constants);
      };
    }
  }