function authenticate(to, from, next) {
    var isAuthenticated = false;
    //this is just an example. You will have to find a better or 
    // centralized way to handle you localstorage data handling 
    if (localStorage.getItem('auth_id')) {
        isAuthenticated = true;

    } else {
        isAuthenticated = false;
    }

    if (isAuthenticated) {

        next('/pay'); // allow to enter route
    } else {
        next(); // go to '/login';
    }
}
const routes = [{
        path: '/login',
        name: 'login',

        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Login/Login.vue')
    },
    {
        path: '/registration',
        name: 'EnterNamePhone',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Registration/EnterNamePhone.vue')
    },
    {
        path: '/forgotpassword',
        name: 'ForgotPassword',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/ForgotPassword/ForgotPassword.vue')
    },
    {
        path: '/enterforgotpin/:verificationCode?/:token?',
        name: 'EnterForgotPin',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/ForgotPassword/EnterForgotPin.vue')
    },

    {
        path: '/otp-verification/:email/:phone',
        name: 'OtpVerification',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/ForgotPassword/OtpVerification.vue'),
        props: {
            default: true
        }
    },
    {
        path: '/signing',
        name: 'signing',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Login/Signing.vue')
    },
    {
        path: '/tip',
        name: 'tip',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // beforeEnter: authenticate,
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Payment/Tip.vue')
    },
    {
        path: '/pay',
        name: 'pay',

        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Payment/Pay.vue')
    },
    {
        path: '/jane-auth',
        name: 'jane-auth',

        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Login/CanpayAuthentication.vue')
    },
    {
        path: '/returns',
        name: 'ReturnTransactionDetails',
        component: () =>
            import ('../components/Payment/ReturnTransactionDetails.vue')
    },
    {
        path: '/banklisting',
        name: 'BankList',
        component: () =>
            import ('../components/Payment/BankList.vue')
    },
    {
        path: '/banklinking',
        name: 'BankLinkingOption',
        component: () =>
            import ('../components/Payment/BankLinkingOption.vue')
    },
]

export default routes